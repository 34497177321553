import MobileMenu from "./modules/MobileMenu";
import $ from "jquery";
import StickyHeader from "./modules/StickyHeader";
import Modal from "./modules/Modal";
import InstaFeed from "./modules/InstaFeed";

var mobileMenu = new MobileMenu();
var stickyHeader = new StickyHeader();
var modal = new Modal();

InstaFeed.run();

console.log('hey')